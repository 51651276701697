import { useAtomValue, useSetAtom } from "jotai";
import { donateModalAtom } from "../../Donate/state";
import { languageAtom } from "../../Layout/Header/state";
import { prioDonationText } from "./content";

function DonationButton() {
  const language = useAtomValue(languageAtom);
  const setMobileOpen = useSetAtom(donateModalAtom);

  const { childrenFood: { donationText } = {} } = prioDonationText[language];

  const handleModal = () => {
    setMobileOpen(true);
  };

  return (
    <div>
      <button
        onClick={handleModal}
        className="bg-yellow-400 text-sm uppercase px-3 font-medium py-1 rounded-full">
        {donationText}
      </button>
    </div>
  );
}

export default DonationButton;
