import { useAtomValue } from "jotai";
import { createContext, useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { IoCreateOutline } from "react-icons/io5";
import useAxios from "../../../axios/useAxios";
import { adminContext } from "../../Context";
import { languageAtom } from "../../Layout/Header/state";
import Error from "../../Reusable/Error";
import Loader from "../../Reusable/Loader";
import NewTeamMemberModal from "../Modals/NewTeamMemberModal";
import { aboutUsContent } from "../data";
import TeamMember from "./TeamMember";

export const teamMemberContext = createContext();

function Team() {
  const { isAdmin } = useContext(adminContext);
  const language = useAtomValue(languageAtom);
  const { team: { teamTitleText } = {} } = aboutUsContent[language];

  const { response: team, error, loading, axiosFetch } = useAxios();

  const [modalShow, setModalShow] = useState(false);

  async function getTeams() {
    axiosFetch({
      url: `teams`
    });
  }

  useEffect(
    () => {
      getTeams();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Container fluid className="teams py-5">
      <teamMemberContext.Provider value={getTeams}>
        <div className="admin-options">
          {isAdmin ? (
            <>
              <IoCreateOutline onClick={() => setModalShow(true)} />
              <NewTeamMemberModal show={modalShow} onHide={() => setModalShow(false)} />
            </>
          ) : null}
        </div>
        <div className="our-team-wrapper">
          <div className="our-team-header mb-5">
            <h2>{teamTitleText}</h2>
          </div>
          <div className="team-container">
            {error && <Error />}
            {loading && <Loader />}
            {team
              .sort((a, b) => b.sequence - a.sequence)
              .map((member) => (
                <TeamMember key={member._id} member={member} reload={getTeams} admin={isAdmin} />
              ))}
          </div>
        </div>
      </teamMemberContext.Provider>
    </Container>
  );
}

export default Team;
