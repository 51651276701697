import { useAtomValue } from "jotai";
import { languageAtom } from "../../Layout/Header/state";
import { homeContentText } from "../content";
import { classNames } from "../../../utils";

function AnniversaryBadge() {
  const language = useAtomValue(languageAtom);
  const isArm = language === "am";

  const { jumbotron: { badge } = {} } = homeContentText(language);

  if (isArm)
    return (
      <div
        className={classNames(
          "lg:mt-2 whitespace-nowrap -translate-y-3 lg:-translate-y-14",
          "text-3xl sm:text-5xl lg:text-7xl xl:text-8xl"
        )}>
        <span className="bg-gold rounded-l-md text-prmDark px-1 md:px-3">{badge}</span>
        <span className="bg-gold rounded-r-md text-white">
          <span className="text-5xl sm:text-7xl lg:text-9xl xl:text-[10rem] drop-shadow-xl font-num mr-1 md:mr-4 font-extrabold">
            30
          </span>
          <span className="font-art text-prmDark">Տարի</span>
        </span>
      </div>
    );

  return (
    <div
      className={classNames(
        "lg:mt-2 bg-gold py-0 px-2 lg:py-2 lg:px-6 rounded-md font-extrabold",
        "text-2xl sm:text-5xl lg:text-7xl"
      )}>
      <span className="text-white mr-1 lg:mr-3">30</span>
      <span className="text-prmDark mt-4">{badge}</span>
    </div>
  );
}

export default AnniversaryBadge;
