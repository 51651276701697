export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const getUrlSearchParamOffset = (url) => {
  if (!url) return undefined;

  const params = new URLSearchParams(url);

  return params.get("offset");
};

//cloudinary transformation values

export const transformedImageUrl = ({ url, width = 400, ratio = false }) => {
  const transformations = ["f_auto", "q_auto", "g_auto", "c_fill", "g_faces"];

  if (width) transformations.push(`w_${width}`);
  if (ratio) transformations.push(`ar_${ratio}`);

  // Check if the URL is not a Cloudinary URL
  if (!url?.includes("cloudinary") || !url.includes("/upload/")) return url;

  // Split the URL at "/upload/"
  const [base, path] = url.split("/upload/");

  // Join transformations and append to base URL
  const transformationsString = transformations.join(",");
  return `${base}/upload/${transformationsString}/${path}`;
};
