import "./style.css";
import { Container } from "react-bootstrap";
import Team from "./Team";
import Story from "./Story";
import DynamicHelmet from "../Helmet/DynamicHelmet";

function AboutUs() {
  return (
    <Container fluid className="about-us">
      <DynamicHelmet title={"HOH | About Us"} />;
      <Story />
      <Team />
    </Container>
  );
}

export default AboutUs;
