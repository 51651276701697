export const aboutUsContent = {
  am: {
    mainTitleText: "Մեր Պատմությունը",
    mainParagraphText: `«Հուսո տուն բարեգործական կենտրոն» հիմնադրամի  հիմնական աշխատանքային կորիզը հանդիսանում են այն նվիրյալները, ովքեր եղել են Երևանի պատվավոր քաղաքացի Գերհարդ Մայեռի կողմից դեռևս 1994թ-ից նախաձեռնած և Գերմանական Կարմիր Խաչի ԲադենՎյուրտեմբերգի երկրամասային կազմակերպության հովանու ներքո իրականացվող միակողմանի մարդասիրական նախաձեռնությունների հիմքում: 
Պետական գրանցում ստանալով 2017թ. մարտին՝  Հիմնադրամն իրականացնում է սոցիալական լարվածության թեթևացմանն ուղղված ծրագրեր և նախաձեռնություններ, հանդիսանալով վերոնշյալ գործունեության շարունակող իրավահաջորդը։
Մեր գործունեության նպատակն է օժանդակել ՀՀ քաղաքացիների սոցիալական և կրթական վիճակի բարելավմանը, նրանց մասնագիտական կարողությունների զարգացմանը, արտակարգ իրավիճակներում գործելու հմտությունների և երկրում դիմակայունության մշակույթի զարգացմանը: 
Հիմնադրամի առջև ծառացած խնդիրներն իրենց ամբողջական լուծումներն են ստանում իրականացվող բարեգործական ծրագրերի միջոցով։ Ներկայումս դրանք վեցն են՝ «Գթության խոհանոց», «Մանկական սնունդ», «Տնային խնամք», «Հույսով լի և առողջ մանկություն», «Աջակցություն Տավուշի մարզի Բերդ խոշորացված համայնքի 6 ցերեկային կենտրոններին» և «Սոցիալական շտապօգնություն»։
Հիմնադրամն ունի իր կողքին մշտապես գտնվող  կամավորների, հովանավորների և աջակիցների հզոր բանակ, ովքեր ջանք ու եռանդ չխնայելով մեր անձնակազմի հետ միասին անում են հնարավոր ամեն բան՝ ձեռք մեկնելով և վաղվա լավ օրվա հույս պարգևելով կյանքի ստվերում հայտնված մարդկանց: 
`,
    readMoreButtonText: "կարդալ ավելին",
    readLessButtonText: "փակել",
    team: {
      teamTitleText: "Մեր Աշխատակիցները",
      teamParagraphText: "Մեր Աշխատակիցները",
      newTeamMemberText: "Նոր Աշխատակից"
    }
  },
  de: {
    mainTitleText: "Unsere Geschichte",
    mainParagraphText: `Der wichtigste Arbeitskern der Stiftung "Wohltätigkeitszentrum Haus der Hoffnung" sind die Eingeweihter, die im Grund der von dem Ehrenbürger von Eriwan, Gerhard Meier, noch seit 1994 initiierten und unter der Schirmherrschaft des Deutschen Roten Kreuzes, Landesverbandes Baden-Württemberg durchführenden einseitigen humanitären Initiativen stehen.
Nach Erhalt der staatlichen Registrierung im März 2017 führt die Stiftung als Nachfolger der oben genannten Tätigkeit Projekte und Initiativen zum Abbau sozialer Spannungen durch.
Der Zweck unserer Tätigkeit ist es, die Verbesserung des Sozial- und Bildungszustandes der BürgerInnen der Republik Armenien, die Entwicklung ihrer beruflichen Fähigkeiten, die Fähigkeiten zur Notfallreaktion und die Entwicklung der Kultur der Resilienz im Land zu unterstützen.  
Die Probleme, mit denen die Stiftung konfrontiert ist, werden durch die durchführenden Wohltätigkeitsprojekte vollständig gelöst. Derzeit gibt es sechs davon: „Küche der Barmherzigkeit“, „Kinderspeisung“, „Hauspflege“, „Hoffnungsvolle und gesunde Kindheit“, „Unterstützung an die 6 Tageszentren der vergrösserten Gemeinde Berd der Region Tawusch“ und „Soziele Soforthilfe“. 
Die Stiftung hat eine starke Armee von Ehrenamtlichen, Förderern und Unterstützern, die ständig an ihrer Seite sind, die gemeinsam mit unseren Mitarbeitern keine Mühe und Energie scheuen, alles Mögliche tun, eine helfende Hand den Menschen zu reichen und ihnen Hoffnung auf einen besseren Tag zu schenken, die in Schatten des Lebens geraten sind. 
`,
    readMoreButtonText: "mehr lesen",
    readLessButtonText: "weniger lesen",
    team: {
      teamTitleText: "Unser Mitarbeiter",
      teamParagraphText: "Unser Mitarbeiter",
      newTeamMemberText: "Neuer Mitarbeiter"
    }
  },
  en: {
    mainTitleText: "Our Story",
    mainParagraphText: `The main working core of the "House of Hope charity center" foundation are the devotees who were initiated by the honorary citizen of Yerevan Gerhard Mayer since 1994 and on the basis of unilateral humanitarian initiatives implemented under the auspices of the Baden-Württemberg territorial formation of the German Red Cross.
Having received state registration on March 2017, the Foundation implements projects and initiatives aimed at easing social tension, being the continuing successor of the above activities.
The aim of our activity is to support the improvement of the social and educational condition of RA citizens, the development of their professional abilities, the skills to act in emergency situations and the development of the culture of resilience in the country.
The problems faced by the foundation get their complete solutions through the implemented charity projects.
 Currently, there are six of them: "Charity Kitchen", "Children's Food", "Home Care", " Healthy and Hopeful Childhood", "Support to 6 Day Care Centers of Berd Enlarged Community of Tavush Region" and "Social Immediate Aid".
The foundation has a strong army of volunteers, sponsors and supporters who are always by its side, who spare no effort and energy together with our staff, do everything possible, holding out a hand and giving hope for a good day tomorrow to people who are in the shadow of life.
`,
    readMoreButtonText: "read more",
    readLessButtonText: "read less",
    team: {
      teamTitleText: "Our Team",
      teamParagraphText: "Our Team",
      newTeamMemberText: "New Employee"
    }
  }
};
