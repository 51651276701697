import { useAtomValue } from "jotai";
import { ReactComponent as Rectangle } from "../../../components/Home/Volunteers/vectors/frame-rectangular.svg";
import { ReactComponent as Circle } from "../../../components/Home/Volunteers/vectors/frame-round.svg";
import { languageAtom } from "../../Layout/Header/state";
import ShortParagraph from "../../Reusable/ShortParagraph";
import { aboutUsContent } from "../data/index.js";
import { transformedImageUrl } from "../../../utils";

function TheStory() {
  const language = useAtomValue(languageAtom);

  const { mainTitleText, mainParagraphText } = aboutUsContent[language];

  return (
    <div className="about-us-body-history my-5">
      <h2>{mainTitleText}</h2>
      <ShortParagraph data={mainParagraphText} language={language} />
      <div className="about-us-image-wrapper">
        <img
          className="mt-5"
          src={transformedImageUrl({
            url: "https://res.cloudinary.com/di8iypeft/image/upload/v1698090133/HouseOfHope/statics/our-story_wfcp7e.jpg",
            width: 1000
          })}
          alt="about-us-cover"
        />
        <div className="vector-rectangle">
          <Rectangle />
        </div>
        <div className="vector-round">
          <Circle />
        </div>
      </div>
    </div>
  );
}

export default TheStory;
